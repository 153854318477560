import { useContext, useState } from "react";
import theme from "../../theme";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import { AlumDTO, JobHistoryDTO } from "../../models/api/alum";
import RoleHistoryRowV2 from "./RoleHistoryRowV2";
import CloseIcon from "@mui/icons-material/Close";
import { Translation } from "../../lib/constants";
import calendarIcon from "/calendar.svg";
import { AlumProfileEvent } from "../../lib/eventEnums";
import { UserContext } from "../../lib/context";
import { buildUrl, trackGoogleAnalyticsEvent } from "../../lib/utils";
import Divider from "@mui/material/Divider";
import externalLinkInactive from "/link-external-secondary.svg";
import externalLink from "/link-external-01.svg";
import { TooltipWhite } from "../../theme/Tooltip";
import CenterModal from "../CenterModal";
import EmptyList from "../EmptyList";

interface RoleHistoryTimelineProps {
  alum: AlumDTO;
  limitTo?: number;
}

export default function RoleHistoryTimelineV2({
  alum,
  limitTo,
}: RoleHistoryTimelineProps) {
  const { t } = useTranslation(Translation.alumProfile);
  const [user, _setUser] = useContext(UserContext);
  const [showFullHistory, setShowFullHistory] = useState(false);

  const [jobHistoryWithDates, jobHistoryWithNoDates] = alum.job_history
    .slice(0, limitTo)
    .reduce(
      (acc, job) => {
        if (job.start_date || job.end_date) {
          acc[0].push(job);
        } else {
          acc[1].push(job);
        }
        return acc;
      },
      [[] as JobHistoryDTO[], [] as JobHistoryDTO[]],
    );

  const LinkedInButton = ({
    color,
    imgSrc,
  }: {
    color: string;
    imgSrc: string;
  }) => {
    return (
      <Stack direction="row" gap="8px">
        <Typography
          fontSize="14px"
          fontWeight={600}
          lineHeight="20px"
          sx={{
            userSelect: "none",
            color: color,
          }}
        >
          {t("roleHistory.linkedIn")}
        </Typography>

        <Box
          component="img"
          src={imgSrc}
          width="20px"
          height="20px"
          sx={{
            userSelect: "none",
            verticalAlign: "top",
          }}
        />
      </Stack>
    );
  };

  const viewFullHistory = () => {
    trackGoogleAnalyticsEvent({
      event: AlumProfileEvent.ALUM_PROFILE_HISTORY_SEE_ALL,
      type: "alum_profile_see_all_history",
      org: user?.orgName,
    });
    setShowFullHistory(true);
  };

  return (
    <>
      <Box id="role-history">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
          pb="1.5rem"
        >
          <Typography fontWeight={600}>{t("roleHistory.title")}</Typography>

          {alum.linkedin_profile_url ? (
            <a
              style={{
                display: "flex",
                gap: "8px",
                cursor: "pointer",
                textDecoration: "none",
              }}
              href={buildUrl(alum.linkedin_profile_url)}
              target="_blank"
            >
              <LinkedInButton
                color={theme.palette.primaryColor[600]}
                imgSrc={externalLink}
              />
            </a>
          ) : (
            <TooltipWhite
              className="linkedin-tooltip"
              arrow={false}
              title={t("roleHistory.linkedInTooltip")}
            >
              <div>
                {" "}
                <LinkedInButton
                  color={theme.palette.grey[400]}
                  imgSrc={externalLinkInactive}
                />{" "}
              </div>
            </TooltipWhite>
          )}
        </Stack>

        {alum.job_history.length === 0 ? (
          <EmptyList
            icon={calendarIcon}
            iconAlt={t("roleHistory.orgLogoAlt")}
            title={t("roleHistory.empty.title")}
            message={t("roleHistory.empty.subtitle")}
          />
        ) : (
          <Stack width="100%">
            <Stack width="100%">
              {jobHistoryWithDates.map((job, index) => {
                const prevJobFromSameOrg =
                  index > 0 &&
                  jobHistoryWithDates[index - 1].org_name === job.org_name;
                const nextJobFromSameOrg =
                  index < jobHistoryWithDates.length - 1 &&
                  jobHistoryWithDates[index + 1].org_name === job.org_name;
                return (
                  <div key={`job-${index}`}>
                    {index !== 0 && !prevJobFromSameOrg && (
                      <Divider
                        sx={{
                          color: theme.palette.grey[200],
                          marginBottom: "20px",
                        }}
                      />
                    )}
                    <RoleHistoryRowV2
                      job={job}
                      showOrgLogo={!prevJobFromSameOrg}
                      showTimeline={nextJobFromSameOrg}
                      isLastKnownRole={index === 0}
                    />
                  </div>
                );
              })}

              {jobHistoryWithNoDates.map((job, index) => {
                const prevJobFromSameOrg =
                  index > 0 &&
                  jobHistoryWithNoDates[index - 1].org_name === job.org_name;
                const nextJobFromSameOrg =
                  index < jobHistoryWithNoDates.length - 1 &&
                  jobHistoryWithNoDates[index + 1].org_name === job.org_name;
                return (
                  <div key={`no-date-job-${index}`}>
                    {index !== 0 && !prevJobFromSameOrg && (
                      <Divider
                        sx={{
                          color: theme.palette.grey[200],
                          marginBottom: "20px",
                        }}
                      />
                    )}
                    <RoleHistoryRowV2
                      job={job}
                      showOrgLogo={!prevJobFromSameOrg}
                      showTimeline={nextJobFromSameOrg}
                      isLastKnownRole={index === 0}
                    />
                  </div>
                );
              })}
            </Stack>
          </Stack>
        )}

        {limitTo && alum.job_history.length > limitTo && (
          <>
            <Divider
              sx={{
                color: theme.palette.grey[200],
                marginBottom: "20px",
              }}
            />

            <Stack
              direction="row"
              alignItems="center"
              gap="0.5rem"
              width="max-content"
              borderRadius="0.5rem"
              sx={{
                cursor: "pointer",
                color: theme.palette.primaryColor[600],
              }}
              className="see-all-education"
              onClick={viewFullHistory}
            >
              <Typography
                color="inherit"
                fontSize="14px"
                fontWeight={600}
                lineHeight="20px"
                sx={{ userSelect: "none" }}
              >
                {t("roleHistory.showAll")}
              </Typography>
            </Stack>
          </>
        )}
      </Box>

      <CenterModal open={showFullHistory}>
        <Box width="640px" minHeight="50vh" maxHeight="85vh" overflow="scroll">
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
            p="24px"
            borderBottom={`1px solid ${theme.palette.grey[200]}`}
            marginBottom="24px"
          >
            <Typography fontWeight={600}>{t("roleHistory.title")}</Typography>

            <CloseIcon
              style={{ cursor: "pointer" }}
              onClick={() => setShowFullHistory(false)}
            />
          </Stack>

          <Stack width="100%" paddingX="24px">
            {alum.job_history.map((job, index) => {
              const prevJobFromSameOrg =
                index > 0 &&
                alum.job_history[index - 1].org_name === job.org_name;
              const nextJobFromSameOrg =
                index < jobHistoryWithDates.length - 1 &&
                alum.job_history[index + 1].org_name === job.org_name;
              return (
                <div key={index}>
                  {index != 0 && !prevJobFromSameOrg && (
                    <Divider
                      sx={{
                        color: theme.palette.grey[200],
                        marginBottom: "20px",
                      }}
                    />
                  )}

                  <RoleHistoryRowV2
                    key={index}
                    job={job}
                    showOrgLogo={!prevJobFromSameOrg}
                    showTimeline={nextJobFromSameOrg}
                    isLastKnownRole={index === 0}
                  />
                </div>
              );
            })}
          </Stack>
        </Box>
      </CenterModal>
    </>
  );
}
